@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

input[type='radio'] {
  display: none;
}

.user-radio-label {
  position: relative;
  color: var(--Gray-1, #333);
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.8em;
  border: 1px solid #e7e7e7;
  padding: 13px 12px;
  border-radius: 4px;
}

.user-radio-label:before {
  content: '';
  height: 18px;
  width: 18px;
  border: 2px solid #e7e7e7;
  border-radius: 50%;
}

input[type='radio']:checked + .user-radio-label:before {
  height: 18px;
  width: 18px;
  /* border: 2px solid var(--Zupotso-Primary, #ddd); */
  border: 6px solid var(--Zupotso-Primary, #e20b18);
  background-color: white;
}

input[type='radio']:checked + .user-radio-label {
  background-color: white;
  border: 1px solid var(--Zupotso-Primary, #e20b18);
  color: var(--Zupotso-Primary, #e20b18);
}

input[type='radio']:not(:checked) + .user-radio-label:hover {
  border: 1px solid #e20b18; /* Change the border color for unselected cards on hover */
  color: #e20b18;
}

input[type='radio']:not(:checked) + .user-radio-label:hover:before {
  border-color: #e20b18; /* Change the border color for the radio button on hover */
}
