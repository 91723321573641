@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.underline-title-decoration {
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

.underline-striked {
  height: 13%;
  background: linear-gradient(-225deg, #0000 10px, #e20b18 11px) bottom left,
    linear-gradient(-45deg, #0000 10px, #e20b18 11px) bottom right;
  background-size: 60% 11px !important;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linear-gradient {
  background: linear-gradient(-256deg, #0000 10px, #e20b18 11px) bottom left,
    linear-gradient(-69deg, #0000 10px, #e20b18 11px) bottom right;
  background-size: 60% 42px !important;
  background-repeat: no-repeat;
}

.white-underline-color {
  background: linear-gradient(-225deg, #0000 10px, #d2d2d2 11px) bottom left,
    linear-gradient(-45deg, #0000 10px, #d2d2d2 11px) bottom right;
  background-size: 60% 11px !important;
  background-repeat: no-repeat;
}
