.form-control {
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
    height: 42px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding-left: 10px !important;
}
.form-control:hover {
    border-color: #E20B18 !important;
    border: 1.5px solid #E20B18 !important;
    box-shadow: none !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
    border: 0px solid transparent !important;
    height: 42px !important;
    padding-left: 0px !important;
}