@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
